<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div>
      <div v-if="$store.state.user != ''" class="mt-1" style="font-size: 15px">
        <div class="text-center mt-15" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div id="homePage" class="pl-1 pr-1" v-if="!pageLoading">
          <profileHeader />

          <div style="margin-top: -28px">
            <h4 class="text-center white--text pb-3">
              You have posted {{ allPosts.length }} Jobs
            </h4>
            <br />

            <v-layout
              row
              wrap
              class="white--text"
              v-for="(post, i) in allPosts"
              :key="i"
            >
              <v-flex xs8 class="pl-2 pr-1">
                <span
                  ><strong>{{ post.title }}</strong> &nbsp;&nbsp;|
                  <span style=""
                    >&nbsp;
                    <v-chip
                      style="background-color: #e96910; font-size: 11px"
                      class="white--text"
                      x-small
                    >
                      {{ post.getApplications.length }} Applied
                    </v-chip></span
                  ></span
                >
                <br />

                <span class="truncate-text grey--text">{{
                  post.short_description
                }}</span>
              </v-flex>

              <v-flex xs4 class="pr-1 text-right pt-2">
                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() <
                      Date.now() - 24 * 60 * 60 * 1000 && post.isrejected == 0
                  "
                  class="pink--text"
                  >Expired</Span
                >

                <Span v-if="post.isrejected == 1" class="pink--text"
                  >Rejected</Span
                >
                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 0
                  "
                  class="orange--text"
                  >Pending</Span
                >

                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 1
                  "
                  class="green--text"
                  >Active</Span
                >

                <v-menu transition="scale-transition" rounded="lg">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small text class="ml-1" v-bind="attrs" v-on="on">
                      <v-icon class="white--text">more_vert</v-icon></v-btn
                    >
                  </template>
                  <v-card
                    style="background-color: #292732"
                    width="150px"
                    class="text-center pt-2 pb-2 pr-5"
                  >
                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="grey--text mr-1">visibility</v-icon> More
                    </v-btn>
                    <br />
                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="blue--text mr-1">edit</v-icon> Edit
                    </v-btn>
                    <br />

                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="red--text mr-1">delete</v-icon> delete
                    </v-btn>
                  </v-card>
                </v-menu>
              </v-flex>

              <v-flex xs12 class="mt-2 mb-2">
                <v-divider style="border-color: #292732"></v-divider>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import profileHeader from "./profileHeader.vue";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
    allPosts: [],
    items: [{ title: "Detail" }, { title: "Edit" }, { title: "Delete" }],
  }),

  methods: {
    async getAllData() {
      this.pageLoading = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllPosts(tg_id:"${this.$store.state.user.tg_id}"){
               post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address
                language
                message_id

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
               
                  getApplications{
                        application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted

                        getEmployees{
                          user_id
                      tg_id
                      tg_username
                      first_name
                      middle_name
                      last_name
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      cv
                      link
                        }

                }


           }
      }
      `,
          },
        });

        this.allPosts = result.data.data.getAllPosts;
      } catch (err) {
        alert(err);
      }
      this.pageLoading = false;
    },
  },
  created() {
    this.getAllData();
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
}
.truncate-text {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}
</style>
