<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- box-shadow: 0px -20px 0px #4f3606; -->
  <v-layout
    row
    wrap
    class="pl-3 pb-10"
    style="
      padding-top: 8px;
      background-image: linear-gradient(#4f3606, #110f1a);

      margin-left: -18px;
      margin-right: -15px;
    "
  >
    <v-flex xs1 class="text-right">
      <a>
        <v-avatar size="40px" id="userAvt">
          <!-- <v-img
                v-if="message.avatar"
                alt="Avatar"
                src=""
              ></v-img> -->
          <v-icon class="white--text">person</v-icon>
        </v-avatar>
      </a>
    </v-flex>

    <v-flex xs5 class="mt-3 red--text pl-4">
      <h5
        v-if="$store.state.user.first_name != ''"
        class="white--text text-capitalize"
      >
        {{ $store.state.user.first_name + " " + $store.state.user.middle_name }}
      </h5>

      <h5 v-else>Incomplete <a class="ml-1">Edit</a></h5>
    </v-flex>

    <v-flex xs6 class="mt-3 text-right pr-4">
      <a @click="gotoTg" style="text-decoration: none" class="pink--text">
        <h5 v-if="$store.state.language == 'English'">
          Back to Telegram Channel
        </h5>
        <h5 v-else>ወደ ቴሌግራም ቻናል ይመለሱ</h5>
      </a>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    gotoTg() {
      window.location.href = "https://t.me/test_jobs";
    },
  },
  created() {},
};
</script>
<style scoped>
#userAvt {
  background-color: #e96910;
}
</style>
