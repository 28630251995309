import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/components/home";
import login from "@/components/login";

import myJobs from "@/components/myJobs";
import approveJobs from "@/components/jobReview";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/Dashboard",
    name: "home",
    component: home,
  },
  {
    path: "/my-jobs",
    name: "myjobs",
    component: myJobs,
  },

  {
    path: "/approve-jobs",
    name: "approve",
    component: approveJobs,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
