var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.$store.state.user != '')?_c('div',{staticClass:"mt-1",staticStyle:{"font-size":"15px"}},[(_vm.pageLoading)?_c('div',{staticClass:"text-center mt-15"},[_c('v-progress-circular',{attrs:{"size":70,"width":2,"color":"grey","indeterminate":""}})],1):_vm._e(),(!_vm.pageLoading)?_c('div',{staticClass:"pl-1 pr-1",attrs:{"id":"homePage"}},[_c('profileHeader'),_c('div',{staticStyle:{"margin-top":"-28px"}},[_c('h4',{staticClass:"text-center white--text pb-3"},[_vm._v(" You have "+_vm._s(_vm.allPosts.length)+" Jobs to Approve ")]),_c('br'),_vm._l((_vm.allPosts),function(post,i){return _c('v-layout',{key:i,staticClass:"white--text",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pl-2 pr-1",attrs:{"xs8":""}},[_c('span',[_c('strong',[_vm._v(_vm._s(post.title))]),_vm._v("   | "),_c('span',{},[_vm._v("  "),_c('v-chip',{staticClass:"white--text",staticStyle:{"background-color":"#e96910","font-size":"11px"},attrs:{"x-small":""}},[_vm._v(" "+_vm._s(3)+" Applied ")])],1)]),_c('br'),_c('span',{staticClass:"truncate-text grey--text"},[_vm._v(_vm._s(post.short_description))])]),_c('v-flex',{staticClass:"pr-1 text-right pt-2",attrs:{"xs4":""}},[(
                  new Date(post.deadline_date).getTime() <
                    Date.now() - 24 * 60 * 60 * 1000 && post.isrejected == 0
                )?_c('Span',{staticClass:"pink--text"},[_vm._v("Expired")]):_vm._e(),(post.isrejected == 1)?_c('Span',{staticClass:"pink--text"},[_vm._v("Rejected")]):_vm._e(),(
                  new Date(post.deadline_date).getTime() +
                    24 * 60 * 60 * 1000 >
                    Date.now() &&
                  post.isrejected == 0 &&
                  post.isapproved == 0
                )?_c('Span',{staticClass:"orange--text"},[_vm._v("Pending")]):_vm._e(),(
                  new Date(post.deadline_date).getTime() +
                    24 * 60 * 60 * 1000 >
                    Date.now() &&
                  post.isrejected == 0 &&
                  post.isapproved == 1
                )?_c('Span',{staticClass:"green--text"},[_vm._v("Active")]):_vm._e(),_c('v-menu',{attrs:{"transition":"scale-transition","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-card',{staticClass:"text-center pt-2 pb-2 pr-5",staticStyle:{"background-color":"#292732"},attrs:{"width":"150px"}},[(1 == 1)?_c('span',[(
                        new Date(post.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now() &&
                        post.isrejected == 0 &&
                        post.isapproved == 0
                      )?_c('v-btn',{staticClass:"text-capitalize green--text ma-1",attrs:{"text":""},on:{"click":function($event){return _vm.approveJob(post)}}},[_c('v-icon',{staticClass:"green--text mr-1"},[_vm._v("check")]),_vm._v(" Approve ")],1):_vm._e(),(post.isrejected == 1 || post.isapproved == 1)?_c('v-btn',{staticClass:"text-capitalize green--text ma-1",attrs:{"text":""},on:{"click":function($event){return _vm.approveJob(post)}}},[_c('v-icon',{staticClass:"green--text mr-1"},[_vm._v("check")]),_vm._v(" Repost ")],1):_vm._e(),(
                        new Date(post.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now() &&
                        post.isrejected == 0 &&
                        post.isapproved == 0
                      )?_c('v-btn',{staticClass:"text-capitalize pink--text ma-1",attrs:{"text":""},on:{"click":function($event){return _vm.rejectJob(post)}}},[_c('v-icon',{staticClass:"red--text mr-1"},[_vm._v("close")]),_vm._v(" Reject ")],1):_vm._e()],1):_vm._e(),(
                      new Date(post.deadline_date).getTime() +
                        24 * 60 * 60 * 1000 >
                        Date.now() &&
                      post.isrejected == 0 &&
                      post.isapproved == 0
                    )?_c('v-divider',{staticClass:"ml-3",staticStyle:{"border-color":"grey"}}):_vm._e(),_c('v-btn',{staticClass:"text-capitalize white--text ma-1",attrs:{"text":""}},[_c('v-icon',{staticClass:"grey--text mr-1"},[_vm._v("visibility")]),_vm._v(" view ")],1),_c('br'),_c('v-btn',{staticClass:"text-capitalize white--text ma-1",attrs:{"text":""}},[_c('v-icon',{staticClass:"blue--text mr-1"},[_vm._v("edit")]),_vm._v(" Edit ")],1),_c('br'),_c('v-btn',{staticClass:"text-capitalize white--text ma-1",attrs:{"text":""}},[_c('v-icon',{staticClass:"red--text mr-1"},[_vm._v("delete")]),_vm._v(" delete ")],1)],1)],1)],1),_c('v-flex',{staticClass:"mt-2 mb-2",attrs:{"xs12":""}},[_c('v-divider',{staticStyle:{"border-color":"#292732"}})],1)],1)})],2)],1):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }