<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div>
      <div v-if="$store.state.user != ''" class="mt-1" style="font-size: 15px">
        <div class="text-center mt-15" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div id="homePage" class="pl-1 pr-1" v-if="!pageLoading">
          <profileHeader />

          <div style="margin-top: -28px">
            <h4 class="text-center white--text pb-3">
              You have {{ allPosts.length }} Jobs to Approve
            </h4>
            <br />

            <v-layout
              row
              wrap
              class="white--text"
              v-for="(post, i) in allPosts"
              :key="i"
            >
              <v-flex xs8 class="pl-2 pr-1">
                <span
                  ><strong>{{ post.title }}</strong> &nbsp;&nbsp;|
                  <span style=""
                    >&nbsp;
                    <v-chip
                      style="background-color: #e96910; font-size: 11px"
                      class="white--text"
                      x-small
                    >
                      {{ 3 }} Applied
                    </v-chip></span
                  ></span
                >
                <br />

                <span class="truncate-text grey--text">{{
                  post.short_description
                }}</span>
              </v-flex>

              <v-flex xs4 class="pr-1 text-right pt-2">
                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() <
                      Date.now() - 24 * 60 * 60 * 1000 && post.isrejected == 0
                  "
                  class="pink--text"
                  >Expired</Span
                >

                <Span v-if="post.isrejected == 1" class="pink--text"
                  >Rejected</Span
                >
                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 0
                  "
                  class="orange--text"
                  >Pending</Span
                >

                <Span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 1
                  "
                  class="green--text"
                  >Active</Span
                >

                <v-menu transition="scale-transition" rounded="lg">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small text class="ml-1" v-bind="attrs" v-on="on">
                      <v-icon class="white--text">more_vert</v-icon></v-btn
                    >
                  </template>
                  <v-card
                    style="background-color: #292732"
                    width="150px"
                    class="text-center pt-2 pb-2 pr-5"
                  >
                    <span v-if="1 == 1">
                      <v-btn
                        @click="approveJob(post)"
                        text
                        class="text-capitalize green--text ma-1"
                        v-if="
                          new Date(post.deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                            Date.now() &&
                          post.isrejected == 0 &&
                          post.isapproved == 0
                        "
                      >
                        <v-icon class="green--text mr-1">check</v-icon> Approve
                      </v-btn>
                      <v-btn
                        @click="approveJob(post)"
                        text
                        class="text-capitalize green--text ma-1"
                        v-if="post.isrejected == 1 || post.isapproved == 1"
                      >
                        <v-icon class="green--text mr-1">check</v-icon> Repost
                      </v-btn>
                      <v-btn
                        @click="rejectJob(post)"
                        text
                        class="text-capitalize pink--text ma-1"
                        v-if="
                          new Date(post.deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                            Date.now() &&
                          post.isrejected == 0 &&
                          post.isapproved == 0
                        "
                      >
                        <v-icon class="red--text mr-1">close</v-icon> Reject
                      </v-btn>
                    </span>

                    <v-divider
                      class="ml-3"
                      v-if="
                        new Date(post.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now() &&
                        post.isrejected == 0 &&
                        post.isapproved == 0
                      "
                      style="border-color: grey"
                    ></v-divider>
                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="grey--text mr-1">visibility</v-icon> view
                    </v-btn>
                    <br />
                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="blue--text mr-1">edit</v-icon> Edit
                    </v-btn>
                    <br />

                    <v-btn text class="text-capitalize white--text ma-1">
                      <v-icon class="red--text mr-1">delete</v-icon> delete
                    </v-btn>
                  </v-card>
                </v-menu>
              </v-flex>

              <v-flex xs12 class="mt-2 mb-2">
                <v-divider style="border-color: #292732"></v-divider>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import profileHeader from "./profileHeader.vue";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
    allPosts: [],
    items: [{ title: "Detail" }, { title: "Edit" }, { title: "Delete" }],
  }),

  methods: {
    async approveJob(job) {
      const approveConfirm = confirm(
        "Are you sure you want to approve this Job?"
      );
      if (approveConfirm) {
        this.pageLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                approveJob(
                  post_id:${parseInt(job.post_id)}
                  tg_id:"${job.tg_id}"
                  title: "${job.title}"

                  short_description: "${job.short_description
                    .toString()
                    .trim()
                    .replace(/\n/g, "\\n")}"
                  short_image: "${job.short_image}"
                  company_address: "${job.company_address}"
                  deadline_date: "${job.deadline_date}"
                  apply_link: "${job.apply_link}"
                  sex: "${job.sex}"


                  ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            result.data.data.approveJob[0].statusMessage.toString() == "true"
          ) {
            this.getAllDataToApprove();
          } else alert("Not approved, Something went wrong");
        } catch (err) {
          alert(err);
        }

        this.pageLoading = false;
      }
    },

    async rejectJob(job) {
      const approveConfirm = confirm(
        "Are you sure you want to reject this Job?"
      );
      if (approveConfirm) {
        this.pageLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                rejectJob(
                  post_id:${parseInt(job.post_id)}
                  tg_id:"${job.tg_id}"
                  title: "${job.title}"            


                  ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            result.data.data.rejectJob[0].statusMessage.toString() == "true"
          ) {
            this.getAllDataToApprove();
          } else alert("Not rejected, Something went wrong, please try again");
        } catch (err) {
          alert(err);
        }

        this.pageLoading = false;
      }
    },
    async getAllDataToApprove() {
      this.pageLoading = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
          getAllPostsToApprove(tg_id:"${this.$store.state.user.tg_id}"){
                 post_id
                 tg_id
                 title
                  short_description
                  short_image
                  detail_description
                  detail_image
                  company_address
  
                 deadline_date
                  application_format
                  isdetail
                  isdirect
                 apply_link
                  isregistered
                  posted_date
  
                  iscv
                  islinkedin
                  iszerf
                 sex
                 age_above_bellow
                 isthesame
                 isapproved
                 isrejected
                language
                message_id
  
  
             }
        }
        `,
          },
        });

        this.allPosts = result.data.data.getAllPostsToApprove;
      } catch (err) {
        alert(err);
      }
      this.pageLoading = false;
    },
  },
  created() {
    this.getAllDataToApprove();
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
}
.truncate-text {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}
</style>
