import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";

import moment from "moment";

import VueHtmlToPaper from "vue-html-to-paper";

// Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(new Date(value)).format("YYYY-MM-DD hh:mm:ss");
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    firebase.initializeApp({
      apiKey: "AIzaSyAlxB_nlKLufI0fZ2MPylb21NrHkBHSnno",
      authDomain: "jobs-3c99b.firebaseapp.com",
      projectId: "jobs-3c99b",
      storageBucket: "jobs-3c99b.appspot.com",
      messagingSenderId: "808824298683",
      appId: "1:808824298683:web:c8904035c63e86a5541d9e",
      measurementId: "G-L54C1J9LBL",
    });
  },
}).$mount("#app");
