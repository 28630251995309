<template>
  <div>
    <v-app-bar app id="navBar" dark flat v-if="$store.state.user != ''">
      <v-layout row wrap>
        <v-flex xs2 md1 class="ml-2">
          <v-img height="35" width="45" contain src="../assets//logo.jpg">
          </v-img>
        </v-flex>

        <v-flex xs9 class="ml-2">
          <span v-if="$store.state.language == 'English'"
            >Addis Ababa Jobs</span
          >
          <span v-else>አዲስ አበባ ስራዎች</span>
        </v-flex>
      </v-layout>

      <v-spacer v-if="$store.state.user.tg_id == '6160879304'"></v-spacer>
      <v-btn
        v-if="$store.state.user.tg_id == '6160879304'"
        small
        @click="
          $store.dispatch('changeBtn', '');
          $router
            .push({
              name: 'approve',
            })
            .catch((err) => {});
        "
        text
      >
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="$store.dispatch('changeLanguage', 'English')"
        style="font-size: 11px"
        text
        small
        class="text-capitalize"
        v-if="$store.state.language == 'አማርኛ'"
      >
        <span>አማርኛ</span>
      </v-btn>

      <v-btn
        @click="$store.dispatch('changeLanguage', 'አማርኛ')"
        style="font-size: 11px"
        text
        small
        class="text-capitalize"
        v-else
      >
        <span>English</span>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  // computed: {
  //   mobile() {
  //     return this.$vuetify.breakpoint.mdAndDown;
  //   },
  // },
  // watch: {
  //   group() {
  //     this.drawer = false;
  //   },
  // },
};
</script>
<style scoped>
#navBar {
  background-color: #110f1a;
}
</style>
