<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="mt-1">
    <v-form @submit.prevent="">
      <div class="text-center mt-15" v-if="pageLoading">
        <v-progress-circular
          :size="70"
          :width="2"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </div>
      <!-- <v-layout
        row
        wrap
        class="text-center white pa-10 pl-3 pt-5 mt-0"
        id="login"
      >
        <v-flex xs12 class="">
          <p>Bingo Games</p>
          <v-flex xs10 class="mt-4 ml-6 ma-4">
            <v-text-field
              v-model="ipAddress"
              dense
              class="white"
              label="IP Address"
              hide-details="true"
              single-line
              outlined
              @change="
                $store.dispatch(
                  'dogURL',
                  'http://' + ipAddress + ':8136/graphql'
                )
              "
            >
            </v-text-field>
          </v-flex>
          <p class="headline">Admin Login</p>
        </v-flex>
        <v-flex xs12 class="mt-1">
          <v-text-field
            style="font-size: 11px"
            v-model="username"
            :rules="[(v) => !!v || 'Required']"
            label="Username"
            required
            dense
            outlined
            prepend-icon="person"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            style="font-size: 11px"
            v-model="password"
            :rules="[(v) => !!v || 'Required']"
            label="Password"
            required
            dense
            outlined
            type="password"
            prepend-icon="lock"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-btn
            @keyup.enter="adminLoginLoading"
            type="submit"
            dark
            small
            text
            class="primary"
            @click="login"
            id="loginBtn"
            :loading="adminLoginLoading"
          >
            <span class="text-capitalize">Login</span>
          </v-btn>
        </v-flex>
      </v-layout> -->
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    username: "",
    password: "",
    adminLoginLoading: false,
    pageLoading: false,
    ipAddress: "",
    tg_id: "",
    tg_username: "",
  }),

  methods: {
    async login() {
      if (this.tg_id != "") {
        this.pageLoading = true;

        try {
          var userLoginResult = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `{
               userLogin(tg_id:"${this.tg_id}"){                  
                     user_id
                      tg_id
                      first_name
                      middle_name
                      last_name
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      cv
                      link
                      tg_username
                      statusMessage
    
                   }
              }
              `,
            },
          });

          if (userLoginResult.data.data.userLogin.length > 0) {
            this.$store.dispatch(
              "loginUser",
              userLoginResult.data.data.userLogin[0]
            );

            this.$router.push({ name: "home" });
          } else {
            var addResult = await axios({
              method: "POST",
              url: this.$store.state.userURL,

              data: {
                query: `mutation{
                     addUser(
                        tg_id: "${this.tg_id}"
                        tg_username: "${this.tg_username}"
    
                            ){
                              statusMessage
                          }
  
                }
                `,
              },
            });

            if (
              addResult.data.data.addUser[0].statusMessage.toString() == "true"
            ) {
              this.login();
            } else {
              alert("Please try again.");
            }
          }
        } catch (err) {
          alert(err);
        }

        this.pageLoading = false;
      } else alert("Some thing went wrong, please try again");
    },
  },

  computed: {},

  async created() {
    let param1 = "";
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    param1 = params.get("postId");

    this.$store.dispatch("changeParam", param1);

    this.$store.dispatch("loginUser", "");
    this.$store.dispatch("changeBtn", "home");
    //window.Telegram.WebApp
    if (window.Telegram.WebApp) {
      const user = await window.Telegram.WebApp.initDataUnsafe.user;
      this.tg_id = user.id.toString();
      this.tg_username = user.username;

      await window.Telegram.WebApp.expand();
      // await window.Telegram.WebApp.enableClosingConfirmation();
      // await window.Telegram.WebApp.disableVerticalSwipes();
      await window.Telegram.WebApp.setHeaderColor("#110f1a");
      this.login();
    } else {
      this.tg_id = "6160879304";
      this.login();
    }
  },
};
</script>
<style scoped>
#login {
  max-width: 350px;
  margin: auto;
  border-radius: 5px;
}
</style>
