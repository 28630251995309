<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div>
      <div v-if="$store.state.user != ''" class="mt-1" style="font-size: 15px">
        <div class="text-center mt-15" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div id="homePage" class="pl-1 pr-1" v-if="!pageLoading">
          <profileHeader />
          <!-- <v-layout
            row
            wrap
            class="pl-3 pb-10"
            style="
              padding-top: 5px;
              background-image: linear-gradient(#4f3606, #110f1a);
              box-shadow: 0px -20px 0px #4f3606;
              margin-left: -18px;
              margin-right: -15px;
            "
          >
            <v-flex xs1 class="text-right">
              <a>
                <v-avatar size="40px" id="userAvt">
              
                  <v-icon class="white--text">person</v-icon>
                </v-avatar>
              </a>
            </v-flex>

            <v-flex xs5 class="mt-3 red--text pl-4">
              <h5
                v-if="$store.state.user.first_name != ''"
                class="white--text text-capitalize"
              >
                {{
                  $store.state.user.first_name +
                  " " +
                  $store.state.user.middle_name
                }}
              </h5>

              <h5 v-else>Profile <a class="ml-1">Edit</a></h5>
            </v-flex>

            <v-flex xs6 class="mt-3 text-right pr-4">
              <a
                @click="gotoTg"
                style="text-decoration: none"
                class="pink--text"
              >
                <h5 v-if="$store.state.language == 'English'">
                  Back to Telegram Channel
                </h5>
                <h5 v-else>ወደ ቴሌግራም ቻናል ይመለሱ</h5>
              </a>
            </v-flex>
          </v-layout> -->

          <v-layout row wrap class="" style="margin-top: -25px">
            <v-flex xs12 class="mb-4 pl-10 pr-10">
              <a
                @click="
                  postJobDialog = true;
                  tgid = $store.state.user.tg_id;
                "
              >
                <v-card
                  style="
                    background-image: linear-gradient(#31ce5c, #242623);
                    border-radius: 10px;
                  "
                  class="white--text text-center pa-1"
                >
                  <h4>
                    {{
                      $store.state.language == "English"
                        ? "Post a Job"
                        : "ሥራ ይለጥፉ"
                    }}
                  </h4>
                </v-card>
              </a>
            </v-flex>
            <v-flex xs6 md4>
              <a
                @click="
                  $store.dispatch('changeBtn', 'jobs');
                  $router
                    .push({
                      name: 'myjobs',
                    })
                    .catch((err) => {});
                "
              >
                <v-card
                  class="white--text ma-1 mb-2 text-center pt-3 pb-5"
                  height="230px"
                  style="
                    background-image: linear-gradient(#292732, #292732);
                    border-radius: 10px;
                  "
                >
                  <v-icon style="font-size: 50px" x-large class="blue--text">
                    work
                  </v-icon>
                  <h4 class="pt-1 mb-4">My Job Posts</h4>

                  <span>All Posted Jobs: {{ allPosts.length }}</span
                  ><br />
                  <span class="green--text">Active Jobs: {{ activeJobs }}</span
                  ><br />

                  <span>Pending jobs: {{ pendingJobs }}</span
                  ><br />
                  <span class="pink--text">Expired Jobs: {{ expiredJobs }}</span
                  ><br />
                  <span class="pink--text"
                    >Rejected Jobs: {{ rejectedJobs }}</span
                  ><br />
                </v-card>
              </a>
            </v-flex>

            <v-flex xs6 md4>
              <a>
                <v-card
                  class="white--text ma-1 mb-2 text-center pt-3 pb-5"
                  height="230px"
                  style="
                    background-image: linear-gradient(#292732, #292732);
                    border-radius: 10px;
                  "
                >
                  <v-icon style="font-size: 50px" x-large class="blue--text"
                    >desktop_mac
                  </v-icon>

                  <h4 class="pt-1 mb-4">My Applications</h4>

                  <span>Total Applied: {{ allApplications.length }}</span
                  ><br />

                  <span
                    >Pending:&nbsp; &nbsp;
                    {{
                      allApplications.length -
                      acceptedApplications -
                      rejectedApplications
                    }}</span
                  ><br />
                  <span class="green--text"
                    >Accepted:&nbsp; &nbsp;{{ acceptedApplications }}</span
                  ><br />

                  <span class="pink--text"
                    >Rejected:&nbsp; &nbsp; {{ rejectedApplications }}</span
                  ><br />

                  <h6 class="orange--text mt-3" style="font-size: 11px">
                    NB: Only for Employees
                  </h6>
                </v-card>
              </a>
            </v-flex>

            <v-flex xs6 md4>
              <a>
                <v-card
                  class="white--text ma-1 mb-2 text-center pt-5 pb-5"
                  height="230px"
                  style="
                    background-image: linear-gradient(#292732, #292732);
                    border-radius: 10px;
                  "
                >
                  <v-icon style="font-size: 50px" x-large class="green--text">
                    group
                  </v-icon>
                  <h4 class="pt-1">Active Users</h4>
                  <br />
                  {{ "265,987" }}
                </v-card>
              </a>
            </v-flex>
            <v-flex xs6 md4>
              <a>
                <v-card
                  class="white--text ma-1 mb-2 text-center pt-5 pb-5"
                  height="230px"
                  style="
                    background-image: linear-gradient(#292732, #292732);
                    border-radius: 10px;
                  "
                >
                  <v-icon
                    style="font-size: 50px"
                    x-large
                    class="orange--text text--darken-3"
                  >
                    help
                  </v-icon>

                  <h4 class="pt-1 mb-3">Support 24/7</h4>
                  <h5 class="mb-1">Contact Us</h5>

                  <span
                    >Call <a @click="copyNumber">{{ contactPhone }}</a></span
                  >

                  <div class="text-center mt-1">
                    <a href="https://t.me/W3w22121" target="_blank"
                      ><img
                        height="40"
                        width="40"
                        contain
                        src="../assets/tg.png"
                      />
                    </a>
                  </div>
                  <span
                    style="font-size: 11px"
                    v-if="contactCopyMessage != ''"
                    class="green--text"
                    >{{ contactCopyMessage }}</span
                  >
                </v-card>
              </a>
            </v-flex>
          </v-layout>
        </div>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="postJobDialog"
          fullscreen
          persistent
        >
          <v-card style="background-color: #110f1a" class="white--text">
            <v-card-title>
              {{
                $store.state.language == "English" ? "Post a Job" : "ሥራ ይለጥፉ"
              }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="postJobDialog = false"
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-15">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-layout row wrap class="pa-0 mt-1 pr-1 pl-1">
                  <v-flex xs12 class="text-center white--text ma-1">
                    <h4>
                      {{
                        $store.state.language == "English"
                          ? " Please read carefully and complete all the required fields."
                          : "እባክዎን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያጠናቅቁ።"
                      }}
                    </h4>
                  </v-flex>
                  <v-flex xs12 md5 class="mt-2">
                    <span class="white--text ml-2">{{
                      $store.state.language == "English"
                        ? "Telegram Id"
                        : "የቴሌግራም ቁጥር"
                    }}</span>

                    <input
                      required
                      :rules="[(v) => !!v || 'title is required']"
                      v-model="tgid"
                      class="pa-2 pl-3 mb-2"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Telegram Id'
                          : 'የቴሌግራም ቁጥር'
                      "
                    />
                    <br />

                    <input
                      required
                      :rules="[(v) => !!v || 'title is required']"
                      v-model="title"
                      class="pa-2 pl-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Job Title'
                          : 'የስራ መጠሪያ '
                      "
                    />
                    <br />

                    <textarea
                      :rules="[(v) => !!v || 'Description is required']"
                      required
                      class="pa-2 pl-3 mt-2"
                      v-model="shortDescription"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Short Description'
                          : 'አጭር መግለጫ'
                      "
                      rows="2"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        background-color: #292732;
                      "
                    ></textarea>

                    <v-card
                      style="background-color: #292732"
                      elevation="0"
                      light
                      @dragend="OnDragEnter"
                      @dragleave="OnDragLeave"
                      @dragover.prevent
                      @drop="OnDrop"
                      class="text-center mt-1"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref="selectImage"
                        style="display: none"
                        @change="OnInputFile"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref="changeImage"
                        style="display: none"
                        @change="OnInputFile"
                      />

                      <v-img
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-0 mr-0"
                        :src="image1"
                        height="142"
                        contain
                        v-if="image1 != ''"
                        @click="changeImage()"
                      ></v-img>

                      <div
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-5 mr-5 text-center"
                        v-if="image1 == ''"
                        @click="simulateInputfileClicked"
                      >
                        <!-- <v-btn centered outlined large fab class="ma-4"> -->
                        <v-icon centered class="mt-10 white--text"
                          >camera_alt</v-icon
                        >
                        <br />

                        <p class="text-center white--text">
                          {{
                            $store.state.language == "English"
                              ? "Short Description Image"
                              : "አጭር መግለጫ ምስል"
                          }}
                        </p>
                        <br />
                        <br />
                        <!-- </v-btn> -->
                      </div>
                    </v-card>

                    <textarea
                      :rules="[(v) => !!v || 'Description is required']"
                      required
                      class="pa-2 pl-3 mt-2"
                      v-model="detailDescription"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Detail Description'
                          : 'ዝርዝር መግለጫ'
                      "
                      rows="5"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        background-color: #292732;
                      "
                    ></textarea>

                    <v-card
                      style="background-color: #292732"
                      elevation="0"
                      light
                      @dragend="OnDragEnter"
                      @dragleave="OnDragLeave"
                      @dragover.prevent
                      @drop="OnDrop"
                      class="text-center mt-1"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref="selectImage2"
                        style="display: none"
                        @change="OnInputFile2"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref="changeImage2"
                        style="display: none"
                        @change="OnInputFile2"
                      />

                      <v-img
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-0 mr-0"
                        :src="image12"
                        height="142"
                        contain
                        v-if="image12 != ''"
                        @click="changeImage2()"
                      ></v-img>

                      <div
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-5 mr-5 text-center"
                        v-if="image12 == ''"
                        @click="simulateInputfileClicked2"
                      >
                        <!-- <v-btn centered outlined large fab class="ma-4"> -->
                        <v-icon centered class="mt-10 white--text"
                          >camera_alt</v-icon
                        >
                        <br />

                        <p class="text-center white--text">
                          {{
                            $store.state.language == "English"
                              ? "Detail Description Image"
                              : "ዝርዝር መግለጫ ምስል"
                          }}
                        </p>
                        <br />
                        <br />
                        <!-- </v-btn> -->
                      </div>
                    </v-card>

                    <input
                      :rules="[(v) => !!v || 'Location is required']"
                      required
                      v-model="address"
                      class="pa-2 pl-2 mt-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Work Location'
                          : 'የስራ ቦታ'
                      "
                    />
                    <br />
                  </v-flex>
                  <v-flex xs12 class="">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-3 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      v-model="isZerf"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Does it has a sector?"
                            : "ዘርፍ አለው?"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex xs6>
                    <v-switch
                      hide-details
                      class="white--text pl-3 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      v-model="isDetail"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Read details"
                            : "ዝርዝሩን ያንብቡ"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex xs6 class="pl-2">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      v-model="isTheSame"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Same Doc"
                            : "ተመሳሳይ ሰነድ"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex xs6>
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      v-model="isDirect"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Direct Contact"
                            : "በቀጥታ ያነጋግሩ"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex xs6 class="pl-2" v-if="isDirect == 0">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      v-model="isRegistration"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Registration"
                            : "መመዝገብ አለበት"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex xs12 v-if="isDirect == 1">
                    <input
                      required
                      v-model="applyLink"
                      class="pa-2 pl-3 mt-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Phone, Telegram, e-mail, Website, etc..'
                          : 'ስልክ፣ ቴሌግራም፣ ኢሜል፣ ድህረገፅ፣ ወዘተ.'
                      "
                    />
                    <br />
                  </v-flex>

                  <v-flex xs6>
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      v-model="isCv"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "CV Mandatory"
                            : "CV ግዴታ ነው"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>
                  <v-flex xs6 class="pl-2">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      v-model="islinkedin"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "LinkedIn"
                            : "ሊንክዲን"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex
                    class="mt-3 pl-3"
                    xs12
                    style="
                      font-size: 18px;
                      border-radius: 5px;
                      color: white;
                      background-color: #292732;
                    "
                  >
                    <v-radio-group v-model="sex" row class="white--text">
                      <Span
                        ><strong>{{
                          $store.state.language == "English" ? "Sex:" : "ጾታ"
                        }}</strong></Span
                      >
                      &nbsp;&nbsp;&nbsp;
                      <v-radio
                        base-color="white"
                        value="Male"
                        color="white"
                        style="border-color: white; color: white"
                        class="custom-radio"
                      >
                        <template v-slot:label>
                          <span class="white--text">{{
                            $store.state.language == "English" ? "Male" : "ወንድ"
                          }}</span>
                        </template>
                      </v-radio>
                      <v-radio value="Female" color="white">
                        <template v-slot:label>
                          <span class="white--text">{{
                            $store.state.language == "English" ? "Female" : "ሴት"
                          }}</span>
                        </template>
                      </v-radio>

                      <v-radio value="Both" color="white">
                        <template v-slot:label>
                          <span class="white--text">{{
                            $store.state.language == "English" ? "Both" : "ሁለቱም"
                          }}</span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex xs6>
                    <input
                      required
                      v-model="age"
                      class="pa-2 pl-3 mt-4"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #292732;
                      "
                      :placeholder="
                        $store.state.language == 'English' ? 'Age' : 'ዕድሜ'
                      "
                    />
                    <br />
                  </v-flex>

                  <v-flex xs6 class="pl-2">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <input
                          :rules="[(v) => !!v || 'Deadline is required']"
                          v-bind="attrs"
                          v-on="on"
                          required
                          v-model="deadline"
                          class="pa-2 pl-3 mt-4"
                          style="
                            font-size: 16px;
                            border-radius: 5px;
                            color: white;
                            background-color: #292732;
                          "
                          type="text"
                          :placeholder="
                            $store.state.language == 'English'
                              ? 'Deadline Date'
                              : 'የማብቂያ ቀን'
                          "
                        />
                      </template>
                      <v-date-picker
                        v-model="deadline"
                        no-title
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 class="text-center">
                    <v-btn
                      :loading="submitLoading"
                      @click="submitPost"
                      class="pl-15 pr-15 mt-10 white--text"
                      text
                      style="
                        background-image: linear-gradient(#31ce5c, #242623);
                        border-radius: 10px;
                        font-size: 18px;
                      "
                    >
                      <span class="text-capitalize">Submit</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="applyDialog"
          fullscreen
          persistent
          v-if="applyData.length > 0"
        >
          <v-card style="background-color: #110f1a" class="white--text">
            <v-card-title>
              {{ $store.state.language == "English" ? "Apply" : "ያመልክቱ" }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="
                  applyDialog = false;
                  applyData = [];
                  $store.dispatch('changeParam', '');
                "
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-6">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-layout row wrap class="pa-1 mt-1 white--text">
                  <v-flex xs12 class="pl-1">
                    <h4>
                      {{
                        $store.state.language == "English"
                          ? "To apply for the position, please review the details thoroughly and provide all the required information."
                          : "ሥራውን ለማመልከት, እባክዎን ዝርዝሩን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያስገቡ"
                      }}
                    </h4>
                    <br />
                  </v-flex>

                  <v-flex xs12>
                    <span
                      ><Strong>Title: </Strong> {{ applyData[0].title }}</span
                    >
                    <br />
                    <span
                      ><Strong>Address: </Strong>
                      {{ applyData[0].company_address }}</span
                    >
                    <br />
                    <span
                      v-if="
                        new Date(applyData[0].deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                        Date.now()
                      "
                      ><Strong>Deadline: </Strong> {{ getDeadline }}</span
                    >

                    <span v-else class="pink--text"
                      ><Strong>Deadline: </Strong> ( {{ getDeadline }}),
                      Expired</span
                    >
                    <br />
                    <span
                      ><Strong>Applicants needed: </Strong>
                      {{ applyData[0].sex }}</span
                    >
                    <v-img
                      v-if="applyData[0].short_image != ''"
                      class="ml-0 mr-0 mt-2 mb-3"
                      width="100%"
                      height="150"
                      :src="applyData[0].short_image"
                      contain
                    ></v-img>

                    <span style="white-space: pre-line"
                      ><Strong>Summary: </Strong>
                      {{ applyData[0].short_description }}</span
                    >

                    <br />
                    <v-img
                      v-if="applyData[0].detail_image != ''"
                      class="ml-0 mr-0 mt-2 mb-3"
                      width="100%"
                      height="150"
                      :src="applyData[0].detail_image"
                      contain
                    ></v-img>

                    <span style="white-space: pre-line"
                      ><Strong>Detail: </Strong>
                      {{ applyData[0].detail_description }}</span
                    >

                    <br />

                    <br />
                    <span
                      v-if="
                        applyData[0].apply_link != '' &&
                        new Date(applyData[0].deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now()
                      "
                    >
                      <span style="white-space: pre-line mt-3"
                        ><Strong>How To Apply: </Strong> Contact the employer
                        using the given information bellow.
                      </span>
                      <br />
                      <span style="white-space: pre-line"
                        ><Strong>Contact Employer: </Strong>
                        {{ applyData[0].apply_link }}</span
                      >
                    </span>

                    <div
                      v-if="
                        new Date(applyData[0].deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                        Date.now()
                      "
                    >
                      <span
                        v-if="!applyClicked"
                        style="white-space: pre-line mt-3"
                        ><Strong>How To Apply: </Strong> Click the "Apply"
                        button bellow.
                      </span>
                      <div class="text-center mb-15 pb-15" v-if="!applyClicked">
                        <v-btn
                          v-if="!isAlreadyApplied"
                          @click="applyClicked = true"
                          center
                          class="pl-15 pr-15 mt-7 mb-15 white--text"
                          text
                          style="
                            background-image: linear-gradient(#31ce5c, #242623);
                            border-radius: 10px;
                            font-size: 18px;
                          "
                        >
                          <span class="text-capitalize">Apply</span>
                        </v-btn>

                        <v-btn
                          v-else
                          center
                          class="pl-10 pr-10 mt-7 mb-15 white--text"
                          text
                          style="
                            background-image: linear-gradient(#31ce5c, #242623);
                            border-radius: 10px;
                            font-size: 18px;
                          "
                        >
                          <span class="text-capitalize"
                            >You Already Applied</span
                          >
                        </v-btn>
                      </div>
                    </div>

                    <div
                      v-if="
                        new Date(applyData[0].deadline_date).getTime() +
                          24 * 60 * 60 * 1000 <
                        Date.now()
                      "
                    >
                      <div class="text-center mb-15 pb-15">
                        <v-btn
                          center
                          class="pl-10 pr-10 mt-7 mb-15 white--text"
                          text
                          style="
                            background-image: linear-gradient(red, red);
                            border-radius: 10px;
                            font-size: 18px;
                          "
                        >
                          <span class="text-capitalize">Deadline Passed</span>
                        </v-btn>
                      </div>
                    </div>
                  </v-flex>

                  <!-- <v-flex xs12 class="text-center">
                    <v-btn
                      :loading="submitLoading"
                      @click="submitPost"
                      class="pl-15 pr-15 mt-10 white--text"
                      text
                      style="
                        background-image: linear-gradient(#31ce5c, #242623);
                        border-radius: 10px;
                        font-size: 18px;
                      "
                    >
                      <span class="text-capitalize">Submit</span>
                    </v-btn>
                  </v-flex> -->
                </v-layout>
              </v-form>
            </v-card-text>

            <v-card-text
              v-if="applyClicked"
              style="
                border-top: 4px solid #4f3606;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                color: white;
              "
              class="pt-5 pl-3 pr-3 pb-15"
            >
              <div v-if="applyClicked">
                <span v-if="$store.state.user.first_name == ''"
                  >Your profile is currently incomplete. Please
                  <a class="blue--text">Update</a> it with the necessary
                  information to ensure it is complete. <br />
                  <br />
                </span>

                <span
                  >Please write a short note outlining your qualifications and
                  explaining why you are a good fit for this position. This
                  information will be taken into account when the employer
                  reviews your application.</span
                >

                <textarea
                  :rules="[(v) => !!v || 'Cover Letter is required']"
                  required
                  class="pa-2 pl-3 mt-3"
                  v-model="coverLetter"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Write a short cover letter, please'
                      : 'የሽፋን ደብዳቤ ይጻፉ'
                  "
                  rows="5"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    background-color: #292732;
                  "
                ></textarea>

                <input
                  v-if="applyData[0].iszerf"
                  v-model="sector"
                  class="pa-2 pl-3 mt-2"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Which sector do you prefer?'
                      : 'ዘርፍ ያስገቡ'
                  "
                />
                <br />
                <input
                  v-if="applyData[0].islinkedin && $store.state.user.link == ''"
                  v-model="linkedin"
                  class="pa-2 pl-3 mt-3"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #292732;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Include your Portfolio, LinkedIn or GitHub.'
                      : 'የ LinkedIn, Portfolio ወይም GitHub መገለጫህን አስገባ።'
                  "
                />

                <v-card
                  v-if="applyData[0].iscv && $store.state.user.cv == ''"
                  style="background-color: #292732"
                  elevation="0"
                  light
                  @dragend="OnDragEnter"
                  @dragleave="OnDragLeave"
                  @dragover.prevent
                  @drop="OnDrop"
                  class="text-center mt-3 pb-2"
                >
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx"
                    ref="selectCv"
                    style="display: none"
                    @change="handleFileChange"
                  />

                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-5 mr-5 text-center"
                    v-if="image12 == ''"
                    @click="simulateInputfileCV"
                  >
                    <!-- <v-btn centered outlined large fab class="ma-4"> -->
                    <v-icon large centered class="mt-4 white--text"
                      >file_upload</v-icon
                    >
                    <br />

                    <p
                      class="text-center white--text mt-1"
                      v-if="pdfUploadStatus == ''"
                    >
                      {{
                        $store.state.language == "English"
                          ? "Upload Your CV/Resume"
                          : "CV ያያይዙ"
                      }}
                    </p>

                    <p class="text-center white--text mt-1" v-else>
                      {{ pdfUploadStatus }}
                    </p>
                  </div>
                </v-card>
              </div>

              <div v-if="applyClicked" class="text-center">
                <v-btn
                  v-if="!isAlreadyApplied"
                  :loading="submitLoading"
                  @click="applyJob"
                  center
                  class="pl-10 pr-10 mt-5 white--text"
                  text
                  style="
                    background-image: linear-gradient(#31ce5c, #242623);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                >
                  <span class="text-capitalize">Complete Application </span>
                </v-btn>

                <v-btn
                  v-else
                  center
                  class="pl-10 pr-10 mt-5 white--text"
                  text
                  style="
                    background-image: linear-gradient(#31ce5c, #242623);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                >
                  <span class="text-capitalize">You Already Applied </span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import profileHeader from "./profileHeader.vue";

import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
    profileHeader,
  },
  data() {
    return {
      pageLoading: false,
      loadingCount: 0,
      dateDialog: false,
      deadline: "",
      menu: false,
      valid: true,
      submitLoading: false,
      isDetail: 1,
      isDirect: 0,
      isRegistration: 0,
      isCv: 0,
      islinkedin: 0,
      isZerf: 0,
      isTheSame: 1,
      sex: "",
      age: "",
      tgid: "",

      postJobDialog: false,

      title: "",
      shortDescription: "",
      detailDescription: "",
      address: "",
      applyLink: "",

      image1: "",
      imageUrl1: "",

      image12: "",
      imageUrl12: "",
      files: [],
      files2: [],
      allPosts: [],
      activeJobs: 0,
      pendingJobs: 0,
      rejectedJobs: 0,
      expiredJobs: 0,
      applyData: [],
      applyDialog: false,
      applyClicked: false,
      coverLetter: "",
      sector: "",
      linkedin: "",
      pdf: null,
      cvUrl: "",

      pdfUploadStatus: false,

      allApplications: [],
      contactPhone: "0918440700",
      contactCopyMessage: "",
      acceptedApplications: 0,
      rejectedApplications: 0,
      isAlreadyApplied: false,
    };
  },

  computed: {
    getDeadline() {
      return this.formatDate(this.applyData[0].deadline_date);
    },
  },

  methods: {
    copyNumber() {
      navigator.clipboard.writeText(this.contactPhone).then(() => {
        this.contactCopyMessage = "Phone number copied";

        setTimeout(() => {
          this.contactCopyMessage = "";
        }, 3000);
      });
    },
    async handleFileChange(event) {
      this.pdf = event.target.files[0];
      this.pdfUploadStatus = "Uploading...";

      try {
        var storageReference = firebase
          .storage()
          .ref("jobimages/cv" + this.pdf.name);

        await (await storageReference.put(this.pdf)).ref
          .getDownloadURL()
          .then(async (url) => {
            this.cvUrl = url;

            var result = await axios({
              method: "POST",
              url: this.$store.state.userURL,

              data: {
                query: `mutation{
                attachCv(
                  user_id:${parseInt(this.$store.state.user.user_id)}
                  cvurl:"${this.cvUrl}"                         


                  ){
                 statusMessage
              }
              
              }
              `,
              },
            });

            if (
              result.data.data.attachCv[0].statusMessage.toString() == "true"
            ) {
              this.pdfUploadStatus = "Uploaded Successfully!.";
            } else this.pdfUploadStatus = "Uploading Error, Please try again";
          });
      } catch (err) {
        this.pdfUploadStatus = "Uploading Error, Please try again";
      }

      // uploadTask.on(
      //   "state_changed",
      //   (snapshot) => {
      //     this.pdfUploadStatus = "Uploading...";
      //   },
      //   (error) => {
      //     this.pdfUploadStatus = "Uploading Error, Try again";
      //   },
      //   () => {
      //     // Handle successful uploads
      //     uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      //       alert("File available at", downloadURL);

      //       this.pdfUploadStatus = "Uploading Success!.";
      //     });
      // //   }
      // );
    },
    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },

    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    OnDragEnter(e) {
      e.preventDefault();
      console.log("entering");
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      console.log("leaving");
    },

    OnDrop(e) {
      console.log("dropping");
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;
      console.log(files);
      Array.from(files).forEach((file) => this.addImage(file));
    },
    OnInputFile(e) {
      const files = e.target.files;
      this.files = [];
      Array.from(files).forEach((file) => this.addImage(file));
      console.log(this.files);
    },
    simulateInputfileClicked() {
      this.$refs.selectImage.click();
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        console.log(`${file.name} is not an image`);
        return;
      }
      this.files.push(file);
      const reader = new FileReader();
      reader.onload = (f) => {
        this.image1 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage() {
      this.$refs.changeImage.click();
    },
    async uploadImage() {
      var storageReference = firebase
        .storage()
        .ref("jobimages/image" + Math.random() + ".jpg");

      await (await storageReference.put(this.files[0])).ref
        .getDownloadURL()
        .then((url) => {
          this.imageUrl1 = url;
          console.log(this.imageUrl + "  url1");
        });
    },

    //image2

    OnInputFile2(e) {
      const files = e.target.files;
      this.files2 = [];
      Array.from(files).forEach((file) => this.addImage2(file));
      console.log(this.files2);
    },
    simulateInputfileClicked2() {
      this.$refs.selectImage2.click();
    },

    simulateInputfileCV() {
      this.$refs.selectCv.click();
    },
    addImage2(file) {
      if (!file.type.match("image.*")) {
        console.log(`${file.name} is not an image`);
        return;
      }
      this.files2.push(file);
      const reader = new FileReader();
      reader.onload = (f) => {
        this.image12 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage2() {
      this.$refs.changeImage2.click();
    },
    async uploadImage2() {
      var storageReference = firebase
        .storage()
        .ref("jobimages/image" + Math.random() + ".jpg");

      await (await storageReference.put(this.files2[0])).ref
        .getDownloadURL()
        .then((url) => {
          this.imageUrl12 = url;
        });
    },

    // formatDate(date) {
    //   var d = new Date(date),
    //     month = "" + (d.getMonth() + 1),
    //     day = "" + d.getDate(),
    //     year = d.getFullYear();

    //   if (month.length < 2) month = "0" + month;
    //   if (day.length < 2) day = "0" + day;

    //   return [year, month, day].join("-");
    // },

    async getAllData() {
      this.pageLoading = true;
      try {
        let result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllPosts(tg_id:"${this.$store.state.user.tg_id}"){
               post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                language
                message_id



           }
      }
      `,
          },
        });

        this.allPosts = result.data.data.getAllPosts;
        this.expiredJobs = 0;
        this.rejectedJobs = 0;
        this.pendingJobs = 0;
        this.activeJobs = 0;
        for (let i = 0; i < this.allPosts.length; i++) {
          if (
            new Date(this.allPosts[i].deadline_date).getTime() <
              Date.now() - 24 * 60 * 60 * 1000 &&
            this.allPosts[i].isrejected == 0
          ) {
            this.expiredJobs++;
          }

          if (this.allPosts[i].isrejected == 1) {
            this.rejectedJobs++;
          }

          if (
            new Date(this.allPosts[i].deadline_date).getTime() +
              24 * 60 * 60 * 1000 >
              Date.now() &&
            this.allPosts[i].isrejected == 0 &&
            this.allPosts[i].isapproved == 0
          ) {
            this.pendingJobs++;
          }

          if (
            new Date(this.allPosts[i].deadline_date).getTime() +
              24 * 60 * 60 * 1000 >
              Date.now() &&
            this.allPosts[i].isrejected == 0 &&
            this.allPosts[i].isapproved == 1
          ) {
            this.activeJobs++;
          }
        }
      } catch (err) {
        alert(err);
      }

      try {
        let resultapp = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllApplications(user_id:${parseInt(this.$store.state.user.user_id)}){
                       application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted
    

           }
      }
      `,
          },
        });

        this.allApplications = resultapp.data.data.getAllApplications;
        this.acceptedApplications = 0;
        this.rejectedApplications = 0;

        for (let k = 0; k < this.allApplications.length; k++) {
          if (this.applyData[0].post_id == this.allApplications[k].post_id)
            this.isAlreadyApplied = true;
          if (this.allApplications[k].isaccepted == 1) {
            this.acceptedApplications++;
          } else if (this.allApplications[k].isaccepted == 2) {
            this.rejectedApplications++;
          }
        }
      } catch (err) {}
      this.pageLoading = false;
    },

    async getApplyData() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getJobById(post_id: ${parseInt(this.$store.state.paramId.toString())}){
               post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                language
                message_id


           }
      }
      `,
          },
        });

        this.applyData = result.data.data.getJobById;

        this.applyDialog = true;
      } catch (err) {}
    },

    async submitPost() {
      if (this.files.length < 1 || this.files2.length < 1) {
        alert("Please Upload all images...");
        return;
      }

      if (this.title == "") {
        alert("Please enter title");
        return;
      }

      if (this.shortDescription == "") {
        alert("Please enter short description");
        return;
      }
      if (this.detailDescription == "") {
        alert("Please enter detail description");
        return;
      }

      if (this.address == "") {
        alert("Please enter company address");
        return;
      }

      if (this.deadline == "") {
        alert("Please enter deadline date");
        return;
      }

      if (1 == 1) {
        this.submitLoading = true;
        try {
          await this.uploadImage();
          await this.uploadImage2();
        } catch (error) {
          console.log(error);
        }
        try {
          axios.defaults.headers.post["Content-Type"] =
            "application/json;charset=UTF-8";
          axios.defaults.headers.get["Accept"] =
            "application/json;charset=UTF-8";
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,
            data: {
              query: `
                 mutation {
                  addPost(
                   tg_id:"${parseInt(this.tgid)}"
                    title:"${this.title}"
                    short_description:"${this.shortDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    short_image:"${this.imageUrl1}"

                    detail_description:"${this.detailDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    detail_image:"${this.imageUrl12}"
                    company_address:"${this.address}"
                    deadline_date:"${this.deadline}"

                    application_format:"${""}"
                    sex:"${this.sex}"
                    isdetail:${this.isDetail + 0}
                    isdirect: ${this.isDirect + 0}
                    apply_link:"${this.applyLink}"
                    isregistered: ${this.isRegistration + 0}

                    iscv: ${this.isCv + 0}
                    islinkedin: ${this.islinkedin + 0}
                    iszerf: ${this.isZerf + 0}
                    isthesame: ${this.isTheSame + 0}
                    age_above_bellow:"${this.age}"
                    language: "${this.$store.state.language}"

                    ){
                        statusMessage
                    }
                    }
              `,
            },
          });
          if (result.data.data.addPost[0].statusMessage.toString() == "true") {
            alert(
              this.$store.state.language == "English"
                ? "Your Job is submited successfully, Please wait until it is approved by Admin."
                : "ሥራዎ በተሳካ ሁኔታ ተመዝግቧል፣ እባክዎ በአስተዳዳሪው እስኪፀድቅ ድረስ ይጠብቁ።"
            );
            this.postJobDialog = false;
            await this.getAllData();
          } else {
            alert(
              this.$store.state.language == "English"
                ? "Something went wrong, please try again"
                : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
            );
          }
        } catch (err) {
          alert(err);
        }
        this.submitLoading = false;
      } else
        alert(
          this.$store.state.language == "English"
            ? " Please read carefully and complete all the required fields."
            : "እባክዎን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያጠናቅቁ።"
        );
    },

    async applyJob() {
      if (1 == 1) {
        this.submitLoading = true;
        try {
          axios.defaults.headers.post["Content-Type"] =
            "application/json;charset=UTF-8";
          axios.defaults.headers.get["Accept"] =
            "application/json;charset=UTF-8";
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,
            data: {
              query: `
                 mutation {
                  applyJob(
                   post_id:${this.applyData[0].post_id}
                    user_id:${parseInt(this.$store.state.user.user_id)}
                    cover_letter:"${this.coverLetter
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    sector:"${this.sector}"
                    linkedin:"${this.linkedin}"                 

                    ){
                        statusMessage
                    }
                    }
              `,
            },
          });
          if (result.data.data.applyJob[0].statusMessage.toString() == "true") {
            alert(
              this.$store.state.language == "English"
                ? "Application Submitted Successfully"
                : "ማመልከቻው በተሳካ ሁኔታ ተጠናቋል።"
            );
            this.applyDialog = false;
            this.applyData = [];
            this.$store.dispatch("changeParam", "");
            await this.getAllData();
          } else {
            alert(
              this.$store.state.language == "English"
                ? "Something went wrong, please try again"
                : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
            );
          }
        } catch (err) {
          alert(err);
        }
        this.submitLoading = false;
      } else
        alert(
          this.$store.state.language == "English"
            ? " Please read carefully and complete all the required fields."
            : "እባክዎን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያጠናቅቁ።"
        );
    },
  },

  async created() {
    this.applyData = [];
    if (this.$store.state.user != "") {
      if (
        this.$store.state.paramId != "" &&
        this.$store.state.paramId != null
      ) {
        this.getApplyData();
      }
      await this.getAllData();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
}

#userAvt {
  background-color: #e96910;
}

input {
  width: 100%;
  color: white;
}

input::placeholder {
  color: white;
  opacity: 1;
}

textarea {
  width: 100%;
  color: white;
}

textarea::placeholder {
  color: white;
  opacity: 1;
}

#imageCamera {
  background-color: #292732;
  font-size: 16px;
}

.v-radio .v-icon {
  color: red !important;
}
</style>
